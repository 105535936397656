import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const EtsyShop = () => (
  <StaticQuery
    query={graphql`
      query {
        allEtsyListing {
          nodes {
            id
            title
            price
            taxonomy_path
            tags
            url
            childEtsyListingImage {
              childFile {
                childImageSharp {
                  fluid(maxWidth: 760, maxHeight: 760) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="shop">
        <Border />
        <Container>
          <Grid>
            {data.allEtsyListing.nodes.map(item => (
              <ItemSection key={item.id}>
                <a href={item.url}>
                  <Art>
                    <Img
                      fluid={
                        item.childEtsyListingImage.childFile.childImageSharp
                          .fluid
                      }
                    alt={item.description}/>
                  </Art>
                  <h4>{item.title}</h4>
                  <p>£{Math.round(item.price)}</p>
                </a>
              </ItemSection>
            ))}
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Border = styled.div`
  border-top: 1px solid;
  border-color: #1e445c;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 40px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  @media (max-width: 1200px) {
    grid-template-columns: 2fr 2fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

`;

const ItemSection = styled.section`
  align-items: center;

  a {
    text-decoration: none;
    font-family: ${props => props.theme.font.secondary};
    color: ${props => props.theme.color.black.regular};
  }

  a:hover {
    cursor: pointer;
    color: #61696f;
  }

  .a:hover .figure {
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
}

  p {
    text-decoration: none;
    font-family: ${props => props.theme.font.primary};
    ${props => props.theme.font_size.small};
  }
`;
const Art = styled.figure`
  margin-bottom: 25px;
  width: 250px;
  height: 250px;
`;

export default EtsyShop;
