import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@common/Footer';
import EtsyShop from '@sections/EtsyShop';

const Shop = () => (
  <Layout>
    <Navbar />
    <EtsyShop />
    <Footer />
  </Layout>
);

export default Shop;
